export enum Role {
  admin = 'admin',
  accountant = 'accountant',
  // 'insurance-rep' = 'insurance-rep',
  'senior-customer-service-rep' = 'senior-customer-service-rep',
  'mid-customer-service-rep' = 'mid-customer-service-rep',
  'junior-customer-service-rep' = 'junior-customer-service-rep',
  viewer = 'viewer',
}

export interface SecurityGroupItem {
  id: string
  createdAt: string
  updatedAt: string
  email: string
  role: Role
  status: 'active' | 'inactive'
}

export interface CreateAdminAccount {
  email: string
  role: Role
}
