// import { ElMessage } from 'element-plus'
import { defineStore } from 'pinia'

import securityGroupApi from '@/api/securityGroup'

import { parseErrors } from '@/helpers/error-parsers'

import { getInitialState } from '.'
import { CreateAdminAccount, SecurityGroupItem } from '@/models/securityGroup'
import { showToast } from '@/helpers/messages'

export const useSecurityGroupStore = defineStore({
  id: 'security-group-store',
  state: getInitialState,
  actions: {
    async getUsers() {
      try {
        this.$patch({ listModule: { isLoading: true, error: null } })

        const {
          data: { data },
        } = await securityGroupApi.usersList()

        this.$patch({
          listModule: {
            list: data,

            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      } catch (e) {
        this.$patch({
          listModule: {
            error: parseErrors(e).error,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      }
    },

    async inviteUser(params: CreateAdminAccount) {
      try {
        await securityGroupApi.inviteUser(params)
        await this.getUsers()
        showToast({ type: 'success', message: 'New User successfully created' })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({ type: 'error', message: error })
      }
    },

    async updateUserStatus(
      userId: string,
      params: Pick<SecurityGroupItem, 'role' | 'status'>
    ) {
      try {
        if (!this.$state.listModule.list.find(i => i.id === userId)) {
          throw new Error('User data must be received before updating')
        }

        const updatedUser = (
          await securityGroupApi.updateUserStatus(userId, params)
        ).data.data

        this.$patch(state => {
          const list = state.listModule.list

          state.listModule.list = list.map(item => {
            if (item.id === updatedUser.id) {
              return updatedUser
            }
            return item
          })
        })

        showToast({ type: 'success', message: 'Status updated' })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({ type: 'error', message: error })
      }
    },
  },
})
